body {
    background-color: #eeeeee
}

.page-wrap {
    display: flex;
    width: 100%;
    height: 100vh;
}

.main {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    background-color: #eeeeee
}

.content {
    margin-right: 20px;
    margin-top: 100px;
    background-color: #eeeeee
}

.content>.card {
    border-radius: 3px;
}

.tabs li.is-active a {
    border: none
}

.paddingLeftSide {
    padding-left: 220px !important;
}

.bgPrimary {
    background-color: #ff6416 !important;
}

.bgSecundary {
    background-color: #3E51B5 !important;
}

.center-check{
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 20px;
}

.check-left{
    padding-left: 10px;
}

.center-info-card{
    display: flex;
    justify-content: center;
    align-items: top;
}

.row-info-card{
    display: block;
    margin-top: 0;
}

.rolagem-horizontal{
    overflow-y: auto;
}

.content-action{
    display: flex;
    flex-direction: column;
}

.btnDefault {
    background-color: #eee !important;
    color: #ff6416 !important;
    border: solid 1px #ccc;
}

.is-default {
    background-color: #eee;
    color: #000;
    border: solid 1px #ccc;
}

.no-button {
    cursor: pointer;
}

.btnPrimary {
    background-color: #ff6416 !important;
    color: #fff;
}

.clear {
    width: 100%;
    clear: both;
    height: 0.01em;
}

.clear5 {
    width: 100%;
    clear: both;
    height: 5px;
}

.clear10 {
    width: 100%;
    clear: both;
    height: 10px;
}

.clear15 {
    width: 100%;
    clear: both;
    height: 15px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.clear20 {
    width: 100%;
    clear: both;
    height: 20px;
}

.clear25 {
    width: 100%;
    clear: both;
    height: 25px;
}

.clear30 {
    width: 100%;
    clear: both;
    height: 30px;
}

.clear35 {
    width: 100%;
    clear: both;
    height: 35px;
}

.clear40 {
    width: 100%;
    clear: both;
    height: 40px;
}

.clear50 {
    width: 100%;
    clear: both;
    height: 50px;
}

.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
}

.alert-message {
    position: fixed;
    top: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.badge {
    position: absolute;
    top: 15px;
    left: 0px;
    padding: 5px 10px;
    border-radius: 100%;
    background: white;
    color: red;
  }

.scroll-notification {
    overflow: scroll;
    height: 430px;
    width: 200px;
    margin-left: -70%
}

.container-label {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.check-consultor {
    height: 18px;
    width: 18px;
    background-color: #3E51B5;
}

.center-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 85px;
    margin-top: 85px;
}

.alert-message .notification {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.lista-parceiro{
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 10px;
}

@media (max-width: 700px){
    .lista-parceiro{
        flex-direction: column
    }
}
    