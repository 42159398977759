/* .gr__localhost {
    background-color: #ff6416;
} */

.notecard {
    width: 200px;
}

.page-wrap {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #ff6416;
}

/* .card-login::before, .card-login::after{
    box-sizing: inherit;
    border-radius: 15px;
} */

.content-login {
    margin: auto;
    padding: 20px;

}

.select-cadastro {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 15px 0;
    width: 100%;
}

.label-login {
    margin: auto;
}

.select-login {
    width: 100%;
}

.container-login {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img-login {
    width: 250px;
    padding: 5px;
}

.nova-conta {
    margin-top: 20px;
}

.btn-login {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 30px;
    color: #fff;
    background-color: #ff6416;
    border: 1px solid #ff6416;
    border-radius: 20px;
    padding: 10px 80px;
    cursor: pointer;
    text-transform: uppercase;
}

.btn-login:hover {
    color: #ff6416;
    background-color: #fff;
    border: 1px solid #ff6416;
    transition: 0.2s;
}

.row-login {
    margin: 20px 0;
}