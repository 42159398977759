.contentnav {
    position: fixed;
    width: 100%;
    z-index: 20;
}

.navbar-primary {
    background-color: #ff6416 !important;
    color: #fff !important;
}

.navbar-secundary {
    background-color: #3E51B5 !important;
    color: #fff !important;
}

.navbar a {
    color: #fff;
}

.navbar-primary a:hover {
    color: #ff6416
}

.navbar-secundary a:hover {
    color: #3E51B5
}

.navbar-menu {
    display: flex !important;
    background-color: #ff6416 !important;
    color: #fff !important;
    /*justify-content: space-between;*/
}

.sidebar {
    width: 200px;
    background-color: #fff;
    height: 100vh;
    border-right: solid 1px #ccc;
    position: fixed;
    z-index: 30;
    left: 0px;
}

.sidebar .logo-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: solid 1px #ccc;
    padding-bottom: 20px;
}

.sidebar .logo {
    background-image: url(../images/logo-sidebar.png);
    width: 60px;
    height: 60px;
    background-size: contain;
    margin-top: 20px;
    margin-bottom: 10px
}

.sidebar .logo-name>strong {
    font-weight: bold;
    font-size: 18px;
    padding-right: 10px;
    padding-left: 10px
}

.sidebar .logo-name>span {
    font-size: 10px;
    padding-right: 10px;
    padding-left: 10px
}

.sidebar ul li:first-child {
    border-top: solid 1px #ccc;
}

.sidebar ul li {
    border-bottom: solid 1px #ccc;
}

.sidebar ul li span, .sidebar ul li a {
    padding: 10px 10px 10px 20px;
    display: block;
    border-right: solid 1px #ccc;
    color: #3E51B5;
    cursor: pointer;
}

.sidebar ul li span:hover {
    color: #ff6416;
}

.sidebar ul li.active span {
    color: #ff6416;
    cursor: default;
    border-right: solid 3px #ff6416;
}

.breadcump {
    padding: 5px;
    background-color: #fff;
    font-size: 11px;
    position: fixed;
    width: 100%;
}