.modal-card{
    flex: 1;
    justify-content: center;
    max-width: 500px;
    right: 20px;
}

@media screen and (max-width: 420px){
    .modal-container{
        max-width: 400px;
    }
    .modal-card-head{
        flex-direction: column;
    }
}
@media screen and (max-width: 380px){
    .modal-container{
        max-width: 300px;
    }
    .modal-card-head{
        flex-direction: column;
    }
}

.modal {
    display: flex;
}

.fechar-modal{
    background-color: #ff6416;
    color: #fff
}

.modal-body {
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    color: #000;
}

.confirm .modal-content {
    max-width: 450px;
}

.confirm .modal-body {
    padding: 0px;
}

.confirm h3 {
    font-weight: bold;
    margin-bottom: 10px;
    padding: 10px 10px 0px 10px;
}

.confirm-content {
    border-top: solid 1px #e1e1e1;
    border-bottom: solid 1px #e1e1e1;
    padding: 10px;
    padding-bottom: 20px;
}

.confirm-content em, .confirm-content i {
    font-style: italic
}

.confirm-button {
    text-align: right;
    padding: 10px;
}

.confirm-button button:first-child {
    margin-right: 5px;
}

.form-modal .modal-content {
    max-width: 450px;
}

.form-modal .form-buttons {
    border-top: solid 1px #e1e1e1;
    padding-top: 10px;
    text-align: right
}

.form-modal button:first-child {
    margin-right: 5px
}