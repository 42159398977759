.imovelItem {
    background-color: #fff;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    overflow: hidden;
    border: solid 1px #ccc;
    box-shadow: 1px 1px 1px rgba(58, 58, 58, 0.2);
}

.imovelButton {
    border-radius: 10px;
}

/* .imovelItem .imovelImage {
    width: 100%;
    height: 50%;
    position: relative;
} */

.marginEditAccount{
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.alinhamentoEditLabel{
    margin-right: 10px;
}

.alinamentoBotao{
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.btnAlinhamento{
    display: flex;
    flex-direction: row;
}

.imgCard {
    width: 100%;
    height: 225px;
    margin: 0px;
    display: flex;
    justify-content: center
}

.tituloMidia{
    margin: 0;
    padding-left: 10px;
}

.butaoListagemMidia {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.imovelItem .imovelImage .info {
    position: absolute;
    margin: 10px;
}

.imovelItem .imovelInfo {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    /* height: 400px; */
}

.imovelItem .imovelInfo .imovelInfoAcoes {
    /*margin-right: 10px;*/
    /* height: 30px; */
}

.atendimento-info-imovel {
    display: flex;
    justify-content: space-between;
    align-items: center
}